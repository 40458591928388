import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./logotype-playboom')),
  freedomcasino: loadable(() => import('./logotype-freedomcasino')),
  wettenlive: loadable(() => import('./logotype-wettenlive')),
  playboom24: loadable(() => import('./logotype-playboom24')),
  casinosky: loadable(() => import('./logotype-casinosky')),
  casinoskyjp: loadable(() => import('./logotype-casinosky')),
  paradiseplay: loadable(() => import('./logotype-paradiseplay')),
}

export const Logotype = createBrandedComponent('Logotype', BRANDED_COMPONENTS)
