import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Theme from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as Constants from './constants'
import * as CombinedSelectors from './combined-selectors'
import * as Icons from './icons'
import { Badge } from './badge'
import { baseStyles } from './menu-item'
import { useBrand } from './use-brand'

const DisabledMenuLink = styled.span`
  ${baseStyles};
  color: ${Theme.color('g-text', 0.3)};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active,
  .active {
    background-color: initial;
    box-shadow: initial;
  }

  &.active {
    color: ${Theme.color('g-text', 0.3)};
  }
`

const MenuLink = styled(ReactRouter.NavLink)`
  ${baseStyles};
  color: ${Theme.color('nav-item')};
`

const hasSeenSports = CombinedSelectors.createSeenSportsSelector()

export function SportsMenuItem(props) {
  const i18n = I18n.useI18n()
  const brand = useBrand()
  const hasSportsSeen = ReactRedux.useSelector(hasSeenSports)
  const casinoSkyBrand = [
    Constants.Brands.CASINOSKY,
    Constants.Brands.CASINOSKYJP,
  ].includes(brand)
  const showNewBadge = casinoSkyBrand && !hasSportsSeen

  if (props.disabled) {
    return (
      <DisabledMenuLink data-testid={props.testId}>
        <Icons.Ball />
        <Common.Box
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
          fontFamily="head"
        >
          {props.children}
        </Common.Box>
      </DisabledMenuLink>
    )
  }
  return (
    <MenuLink data-testid={props.testId} to="/sports">
      <Icons.Ball />
      <Common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
        fontFamily="head"
      >
        {props.children}
      </Common.Box>
      {showNewBadge && <Badge>{i18n.translate('badge.new')}</Badge>}
    </MenuLink>
  )
}

SportsMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
}
