import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import * as common from '@rushplay/common'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'

import * as icons from './icons'
import { baseStyles } from './menu-item'

// Same style as other menu-items
const MenuLink = styled(ReactRouter.Link)`
  ${baseStyles};
  color: ${t.color('nav-item')};
  cursor: pointer;
`

export function SupportChatMenuItem(props) {
  const hasUnreadMsgs = props.unreadMessages > 0

  const variants = {
    active: {
      rotate: [0, 20, -20, 20, -20, 0],
      transition: {
        duration: 1,
        loop: Infinity,
        repeatDelay: 2,
      },
    },
    inactive: {
      rotate: 0,
    },
  }

  return (
    <MenuLink to={props.to} data-testid={props.testId}>
      <motion.div
        variants={variants}
        animate={hasUnreadMsgs ? 'active' : 'inactive'}
      >
        <common.Box color={hasUnreadMsgs ? 'nav-highlight' : null}>
          <icons.Chat />
        </common.Box>
      </motion.div>
      <common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
        fontFamily="head"
      >
        {props.children}
      </common.Box>
    </MenuLink>
  )
}

SupportChatMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  unreadMessages: PropTypes.number,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
}
