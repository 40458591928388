import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

export function Badge(props) {
  return (
    <Common.Box
      display="flex"
      position="absolute"
      justifyContent={['flex-start', 'flex-end']}
      alignItems="center"
      pl="0"
      top="0"
      bottom="0"
      transform="translateY(-50%)"
      // Not the best solution but it's a quick fix for a geman language
      // without a need of changing drawers styles
      right="-28px"
      my="auto"
    >
      <Common.Box
        backgroundColor="badge-background"
        fontSize="8px"
        lineHeight="7px"
        fontWeight="700"
        textAlign="center"
        minWidth="20px"
        padding="0.25em 0.5em"
        borderRadius="100px"
        color="nav"
        fontFamily="head"
      >
        {props.children}
      </Common.Box>
    </Common.Box>
  )
}

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}
