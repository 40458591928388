import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Theme from '@rushplay/theme'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import * as Icons from './icons'
import { baseStyles } from './menu-item'

const DisabledMenuLink = styled.span`
  ${baseStyles};
  color: ${Theme.color('g-text', 0.3)};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active,
  .active {
    background-color: initial;
    box-shadow: initial;
  }

  &.active {
    color: ${Theme.color('g-text', 0.3)};
  }
`

const MenuLink = styled(ReactRouter.NavLink)`
  ${baseStyles};
  color: ${Theme.color('nav-item')};
`

export function StoreMenuItem(props) {
  if (props.disabled) {
    return (
      <DisabledMenuLink data-testid={props.testId}>
        <Icons.Store />
        <Common.Box
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
          fontFamily="head"
        >
          {props.children}
        </Common.Box>
      </DisabledMenuLink>
    )
  }
  return (
    <MenuLink data-testid={props.testId} to="/store">
      <Icons.Store />
      <Common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
        fontFamily="head"
      >
        {props.children}
      </Common.Box>
    </MenuLink>
  )
}

StoreMenuItem.propTypes = {
  authenticated: PropTypes.bool,
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
}
